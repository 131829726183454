import { css } from '@emotion/core'
import { mq } from '../../cssInJs'

export default{
    exTextItem : css(mq({
        overflow:'hidden',
        maxHeight:'0',
        transition: 'all 0.8s',
        padding: '0px',
        marginLeft: ['-1.2rem',null,null,'-4.5rem'],
        marginRight: ['-1.2rem',null,null,'-4.5rem'],
        '&.expand' : {
            maxHeight:'100%',
            padding: '1rem 0'
        }
    }))
}