//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';
import Slider from "react-slick";

export function PageTilesBsyc(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'))
  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx={index}></Tile>
  );

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames('cms_item', 'pageTiles__bsyc', item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} css={style.pageTiles}>
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const colorTheme = page.colorTheme === 'default' ? 'pink' : page.colorTheme;

  const sliderImgs = (page.bottomImageUrl && page.bottomImageUrl !== '') ? page.bottomImageUrl.split("|") : [];

  //const sliderImgs = ["https://dummyimage.com/640x360/333333/fff"];

  if (imageUrl && imageUrl !== '') {
    sliderImgs.unshift(imageUrl);
  }

  const index = props.idx;

  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const mobile = utils.css.screenWidth() <= 550;

  const slides = sliderImgs.map((item, index) =>
    <div key={index}>
      
      <div className='pageTile__bsyc__bg2__inner__wrapper' css={style.tile__bg2__inner__wrapper}>
        <div className="pageTile__bsyc__bg2__inner" css={style.tile__bg2__inner} style={{ backgroundImage: utils.css.bgUrlStyle(item) }}></div>
      </div>

    </div>
  );

  return (

    <div css={style.pageTile__tile} className="pageTile__bsyc">
      <SiteLink className="pageTile__bsyc__content" css={style.tile__content} to={disableLink ? '' : page.pageUrl}>

        <div css={style.tile__flip__wrapper} data-aos={mobile ? (index % 2 === 0 ? 'flip-right' : 'flip-left') : '' }>

          <div className={`pageTile__bsyc__bg__before ${(index % 2 === 0) ? 'even' : 'odd'}`} css={style.tile__bg__before} data-aos={mobile ? '' : 'flip-right'}>
            <div className={`pageTile__bsyc__bg ${(index % 2 === 0) ? 'even' : 'odd'}`} css={style.tile__bg}>
              <div className={`pageTile__bsyc__bg2 ${(index % 2 === 0) ? 'even' : 'odd'}`} css={style.tile__bg2}>
                <Slider {...settings} >
                  {slides}
                </Slider>
              </div>
            </div>
          </div>

          <div data-aos={mobile ? '' : 'flip-right' } css={style.tile__details__before} className={`pageTile__bsyc__content__details__befor ${(index % 2 === 0) ? 'even' : 'odd'}`}>
            <div css={style.tile__details} className={`pageTile__bsyc__content__details ${colorTheme} ${(index % 2 === 0) ? 'even' : 'odd'}`}>
              
              <div className={`pageTile__bsyc__container__wrapper line ${colorTheme}`}></div>

              <div className='pageTile__bsyc__container__wrapper'>
                <div className="pageTile__bsyc__container">
                  {
                    (page.pageTitle && page.pageTitle !== '') &&
                    <div className={`pageTile__bsyc__title ${colorTheme}`}>
                      {page.pageTitle}
                    </div>
                  }

                  {/* <hr className={`pageTile__bsyc__line ${colorTheme}`} /> */}

                  <div className="pageTile__bsyc__desc">
                    {page.tileDesc || page.description}
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>

      </SiteLink>
    </div>
  )
}